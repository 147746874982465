#find-loads-ver-2-page {
  .find-loads-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > img {
      width: 80px;
      height: 80px;
    }
  }
}
