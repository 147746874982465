@import 'variables';
@import 'media-queries';

#find-loads-ver-2-page {
  .search-area {

    .selection-header {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      padding-left: $space-md;

      .search-view-select-container {
        .search-selection-menu {
          display: flex;
          flex-direction: column;
          flex-basis: content;
          margin-right: 24px;
          gap: 14px;
          -webkit-gap: 14px;
          -moz-gap: 14px;
          margin-top: 10px;

          >label {
            margin-left: 0px;
          }
        }

        @include respond-below(md) {
          width: 100%;

          .selection-menu-trigger {
            padding: 6px 0; // keeps clickable area at 35px
          }

          .search-selection-menu {
            flex-direction: row;
            gap: 27px;
            -webkit-gap: 27px;
            -moz-gap: 27px;

            +label {
              margin-right: 20px;
            }
          }
        }

        @include respond-below(xs) {
          flex-basis: 100%;
          justify-content: space-between;

          .search-selection-menu {
            display: flex;
            flex-direction: row;
          }
        }

        @include respond-below(xxs) {
          .search-selection-menu {
            gap: 31px;
            -webkit-gap: 31px;
            -moz-gap: 31px;
          }
        }
      }

      @include respond-below(sm) {
        padding-left: 0;
      }

      @include respond-below(xs) {
        flex-direction: column;
      }

      .main-page-header {
        margin-right: 24px;

        @include respond-below(md) {
          margin-left: 0;
        }
      }

      .search-criteria-input {
        .location-radius {
          display: block;

          @include respond-above(xl) {
            width: 300px;
          }

          @include respond-below(xl) {
            width: 216px;
          }

          @include respond-below(lg) {
            width: 186px;
          }

          @include respond-below(xs) {
            align-items: end;
            display: grid;
            gap: 16px;
            grid-template-columns: 1fr 100px;
            width: 100%;

            .radius-selection.open {
              right: 0;
            }
          }

          .suggestions {
            width: 100%;
            white-space: normal; // allows text to wrap inside dropdown
          }
        }

        .location-selector {
          margin-bottom: 4px;
        }

        .suggestions ul label {
          display: block;
        }
      }

      .arrow {
        margin: 30px 0 0;

        >img {
          height: 24px;
          width: 24px;
        }

        @include respond-below(xl) {
          display: none;
        }
      }

      .date-picker-and-equipment-selection {
        @include respond-below(xs) {
          display: flex;
          flex-basis: 100%;
          justify-content: space-between;

          .equipment-select-controls {
            display: block;
            margin-left: 8px;
            margin-top: 20px;
          }
        }
      }

      .action-buttons {
        display: flex;
        gap: 8px;
        height: 42px;
        margin-top: 20px;

        @include respond-below(xs) {
          flex-direction: row-reverse;
          gap: 16px;
        }

        .btn {
          min-width: 106px;

          @include respond-below(xl) {
            min-width: 0;
          }

          @include respond-below(xs) {
            align-items: stretch;
            width: 50%;
          }
        }
      }
    }

    .search-error {
      margin-top: $space-md;

      @include respond-above(md) {
        padding-left: 192px;
      }

      @include respond-below(md) {
        padding-left: $space-sm;
      }
    }
  }
}
