@import 'variables';

#find-loads-ver-2-page {
  .area-border {
    border-color: $carrier-light-2-grey;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-radius: 4px 4px 0 0;
  }

  .find-loads-detail {
    background-color: $white;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;

    .content-error,
    .content {
      height: 100%;
      overflow: auto;
      padding: 10px 0 20px 0;

      @include respond-mobile-only() {
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .stf-banner {
        padding-right: 18px;
        padding-left: 18px;
        padding-bottom: 10px;
      }
    }

    .content-error {
      padding: 45px 61px 0 61px;

      & > p {
        text-align: center;
      }

      .error-subject {
        margin-bottom: 8px;
        font-weight: $bold;
      }

      .error-recommendation {
        font-size: $font-size-sm;
      }
    }

    .separator-line {
      height: 0;
      border-bottom: 1px solid $carrier-light-2-grey;
      width: 100%;
    }

    .button-area {
      background-color: $white;
      position: relative;
      order: 4;
      bottom: 0;
      z-index: 1;
      flex-direction: column;

      .offer-status {
        text-align: center;
        margin-top: 14px;
        font-size: 0.75rem;
        color: $carrier-mid-3-grey;

        .offer-status-accepted {
          margin-right: 0.813rem;
          font-weight: $bold;
          letter-spacing: 0.05em;
        }

        .accepted-offer-checkmark, .countered-offer-icon {
          align-content: center;
          margin-right: 4px;
          margin-bottom: 2px;
          width: 16px;
          height: 16px;
        }
      }
    }

    .web-exclusive-label {
      order: 3;
      height: 32px;
      border-top: 1px solid #D3D5D6;
      background-color: #F3FFF3;
      padding: 8px 16px;
      line-height: 16px;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      color: #000000;
    }

    .details-pane.azure-map {
      height: 50%;
      padding-right: 18px;
      padding-left: 18px;
      padding-bottom: 10px;
    }
  }
}
