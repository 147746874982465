@import 'variables';
@import 'media-queries';

.equipment-select-controls select {
  &:hover {
    background: $carrier-light-grey-1;
    border-radius: 2px;
  }

  &:active, &:focus {
    background: white;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.60);
    outline: 0;
  }

  @include respond-below(xxs) {
    width: 100%;
  }
}

.equipment-select-controls {
  display: flex;

  #equipment-type, #extended-type {
    border: none;
    cursor: pointer;
    font-size: 13px;
    font-weight: $normal;
    margin-top: 4px;
    overflow: hidden;
    padding: 7px 10px 7px 10px;
    text-overflow: ellipsis;
    white-space: pre;
  }

  #extended-type {
    max-width: 165px;

    @include respond-above(xs) {
      margin-left: 8px;
      width: 125px;
    }
  }
}

.adjust-equipment-select-size {
  @include respond-above(xs) {
    #equipment-type {
      width: 100px;
    }
  }
}
