@import "variables";

.min-max-form-group {
  .min-max-inputs {
    display: flex;
    gap: $space-md;
    > * {
      flex: 1;
    }
  }

}
