@import 'variables';

.chr-modal.find-loads-filter {
  .modal-body {
    padding-top: 8px;
    padding-left: 0px;
    padding-right: 0px;

    .filters-form-content {
      width: 100%;

      .filters-form-section {
        padding-bottom: 24px;

        > * {
          margin-left: $space-md;
          margin-right: $space-md;
        }

        &:not(:first-child()) {
          padding-top: 24px;
        }

        &:not(:last-child()) {
          border-bottom: solid $carrier-light-grey-1 1px;
        }

        &:last-of-type {
          padding-bottom: 0px;
        }

        &.switch-label {
          padding-bottom: 16px;
        }
      }
    }
  }

  .switch-inline {
    padding-left: 0px;
    width: 52%
  }

  .find-loads-filters-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: '8px';

    button {
      min-width: 100px;
    }

    > .btn-link {
      order: 1
    }

    > .btn-primary {
      order: 2
    }
  }
}
