@import 'variables';

#find-loads-ver-2-page {
  .find-loads-results {
    padding: 0 $space-md;

    @include respond-below(md) {
      padding: 0;
    }
  }

  .web-exclusive-tag {
    border-radius: 4px;
    padding: 4px 8px 4px 6px;
    color: #005C05;
    border: 1px solid #005C05;
    background: #F3FFF3;
    height: 24px;
    display: inline-block;
    margin: 0 0 8px 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    float: right;
  }

  .find-loads-result {
    background: $white;
    border: 1px solid $carrier-light-2-grey;

    &:not(:last-child) {
      border-bottom: 1px solid transparent;
    }

    &:first-child {
      border-radius: 4px 4px 0 0;

      @include respond-below(sm) {
        border-radius: 4px;
      }
    }

    &:last-child {
      border-radius: 0 0 4px 4px;

      @include respond-below(sm) {
        border-radius: 4px;
      }
    }

    @include respond-below(xl) {
      .action-area {
        margin: 10px 0;
      }
    }

    @include respond-below(sm) {
      border-radius: 4px;
    }

    cursor: pointer;
    padding: 10px 20px;
    display: grid;
    column-gap: $space-sm;
    grid-template-columns: 2fr minmax(320px, 1fr) 180px;
    grid-template-areas: "pills pills pills" "locations requirements actions";

    @include respond-below(md) {
      grid-template-columns: 1fr 180px;
      grid-template-areas:
        "pills pills" "locations locations" "requirements actions";
    }

    @include respond-above(lg_two_columns_cuttoff) {
      grid-template-columns: 1fr 180px;
      grid-template-areas:
        "pills pills" "locations locations" "requirements actions";
    }

    @include respond-above(xl) {
      grid-template-columns: 2fr minmax(320px, 1fr) 180px;
      grid-template-areas:
        "pills pills pills" "locations requirements actions";
    }

    @include respond-below(md) {
      margin-bottom: $space-xs;

      &:not(:last-child) {
        border-bottom-color: $carrier-light-2-grey;
      }
    }

    @include respond-below(xs) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "pills" "actions" "locations" "requirements";
      padding: 10px;
    }

    @include respond-above(lg_two_columns_cuttoff) {
      &.selected {
        background-color: rgba(0, 113, 199, 0.08);
        border-color: $carrier-blue-lighter;
        position: relative;

        .find-loads-result-locations {
          background-color: #FFFFFF;
        }

        &:before {
          background-color: $carrier-blue-lighter;
          content: '';
          height: 100%;
          position: absolute;
          width: 4px;
        }
      }
    }

    &:not(.selected):hover {
      border: 1px solid $carrier-light-2-grey;
      box-sizing: border-box;
      box-shadow: $card-shadow;
      position: relative;
    }

    .find-loads-result-section {
      padding: 10px;
    }

    .find-loads-result-locations {
      grid-area: locations;
      background-color: $carrier-off-white;
      display: flex;

      @include respond-below(xs) {
        flex-direction: column;
        gap: 12px;

        .arrow {
          display: none;
        }

        .find-loads-result-location {
          padding-left: 24px;
          position: relative;

          &:before {
            background-color: $white;
            border: 2px solid;
            border-radius: 50%;
            content: '';
            display: block;
            height: 14px;
            left: 0;
            position: absolute;
            top: 4px;
            width: 14px;
          }

          &.js-load-origin {
            &:before {
              border-color: $carrier-blue-location-pin;
            }

            &:after {
              background-color: $carrier-grey-location-pin;
              content: '';
              display: block;
              height: 100%;
              left: 6px;
              position: absolute;
              top: 18px;
              width: 2px;
            }
          }

          &.js-load-destination:before {
            border-color: $carrier-grey-location-pin;
          }
        }
      }

      @include respond-above(xs) {

        .arrow {
          align-items: center;
          display: flex;
          margin: 0px 20px;

          >img {
            height: 24px;
            width: 24px;
          }
        }
      }

      .find-loads-result-location {
        flex: 1;
      }
    }

    .find-loads-result-requirements {
      grid-area: requirements;
      display: flex;
      gap: 28px;

      .stops {
        text-transform: lowercase;
      }

      >div {
        min-width: 0; // trunctation for flex child
        flex-basis: 90px;

        &.load-equipment-type {
          flex: 1;
          width: 100px;

          @include respond-above(xxl) {
            >div.truncate {
              -webkit-line-clamp: 1;
            }
          }
        }
      }

      @include respond-below(xs) {
        display: block;

        >div,
        div {
          width: auto;
          display: inline;

          &.load-equipment-type {
            width: auto;
          }

          span {
            color: #363636;
            display: inline-block;
            font-size: 14px;
            padding-right: 20px;
            position: relative;

            &:after {
              background-color: $carrier-grey-location-pin;
              content: '';
              display: block;
              height: 80%;
              position: absolute;
              right: 10px;
              top: 10%;
              width: 1px;
            }
          }
        }

        >div:last-child>div:last-child {
          span:after {
            display: none;
          }
        }
      }
    }

    .find-loads-result-pills-area {
      grid-area: pills;
      padding: 0;

      & .find-loads-result-pills {
        display: inline-block;
        margin-right: 16px;

        & .pill {
          margin-bottom: $space-sm;

          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }

    .find-loads-result-actions {
      grid-area: actions;
      display: contents;
      flex-direction: column;

      >div {
        text-align: right;
      }

      button {
        text-align: right;
      }

      .js-primary-action {
        color: $carrier-navigation-blue;
        font-weight: $bold;
      }

      .offer-amount {
        display: none;
      }

      .time-left {
        color: $count-down-timer;
      }

      .offer-status {
        .offer-status-accepted {
          color: $carrier-mid-3-grey;
          font-weight: 400;
          font-size: 0.75rem;
        }

        .offer-status-label {
          color: $carrier-mid-3-grey;
          font-size: 0.75rem;
        }

        .accepted-offer-checkmark,
        .countered-offer-icon {
          align-content: center;
          margin-right: 4px;
          width: 16px;
          height: 16px;
        }
      }

      @include respond-below(xs) {
        >div {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: flex-end;

          .offer-amount {
            display: block;
          }

          .offer-status>label {
            display: flex;
          }

          .js-offer-status {
            display: flex;
          }

          .js-load-rate {
            align-self: flex-start;
            text-align: left;
            grid-column: 1/2;
          }

          .js-primary-action {
            justify-self: flex-end;
          }
        }
      }
    }
  }
}