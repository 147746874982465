@import 'variables';
@import 'media-queries';

/*
This solution is specific to find-loads-ver2.
This is not the ideal solution for setting scrolling on inner sections, given the current markup.
A better approach would be to correct the page layout accross the site.
Requires the CSS class 'find-loads-scrolling' on body element, when mounted/unmounted.
*/

/*
Short term solution for a scrolling issue discovered in older versions of safari.
Issue: overflow:auto on flex elements is not supported, so nothing is scrollable since the body has overflow:hidden
Fix: prevent these styles so the entire body scrolls - find-loads.component.tsx
*/
  @include respond-above(lg_two_columns_cuttoff) {
    body.find-loads-scrolling {
      height: 100vh;
      overflow: hidden;

      #root {
        height: 100%;

        .app-component {
          height: 100%;

          #app-routes-component {
            display: grid;
            grid-template-rows: 1fr auto;
            height: 100%;
            justify-content: stretch;

            .page-wrapper-component {
              display: grid;
              grid-template-rows: auto 1fr;
              height: 100%;
              overflow: hidden;

              .body-content {
                height: 100%;
                overflow: hidden;

                .available-loads-section {
                  height: 100%;

                  #find-loads-ver-2-page {
                    height: 100%;
                    margin-bottom: 0;

                    .find-loads {
                      display: flex;
                      flex-direction: column;
                      height: 100%;

                      .find-loads-results {
                        height: 100%;
                        min-height: 0;

                        .data-table-component {
                          display: flex;
                          flex-direction: column;
                          height: 100%;

                          .table-container {
                            height: 100%;
                            min-height: 0;

                            .table-contents {
                              display: flex;
                              flex-direction: column;
                              height: 100%;
                              overflow: auto;
                              padding-right: $space-xs; // space content away from scroll bar

                              .find-loads-not-found {
                                height: 100%;
                              }
                            }

                            .table-content-detail {
                              height: 100%;
                            }
                          }

                          .table-footer {
                            @include respond-below(xs) {
                              padding: 0 16px;
                            }

                            .pagination-container {
                              margin-left: auto;
                              margin-right: auto;
                              line-height: 32px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            #bottom-footer {
              justify-self: stretch;
              padding: $space-sm $space-lg;
            }
          }
        }
      }
    }
  }
