@import 'variables';
@import '_controls';
@import 'media-queries';
@import 'find-loads-scrolling.component';

#find-loads-ver-2-page {
  h1.main-page-header {
    margin-left: $space-md;
    white-space: nowrap;
  }

  .back-to-find-loads-btn-icon:before {
    background-image: url('/assets/images/arrow-left.svg');
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: text-top;
  }

  .find-loads {

    .find-loads-results {
      @include use-fancy-scrollbars();

      .table-header.mobile-view {

        // For mobile view, show refresh button just as an icon
        .refresh-button {
          flex-basis: 50px;
          flex-shrink: 0;
          font-size: 0px; //Hide text
          border: none;
          background-color: transparent;
          padding: unset;

          > svg {
            width: unset;
            height: unset;
          }

        }
      }
    }
  }

  .chr-modal.commodities,
  .commodity-details {
    .single-commodity {
      display: block;
      border-bottom: 1px solid $carrier-light-2-grey;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }
}
