@import "../../../../../node_modules/react-dates/lib/css/_datepicker.css";
@import "variables";
$highlight-today-border-color: #bbbbbb;
$selected-day-fill-color: #0071C7;
$date-range-fill-color: #b3d6e7;
$hover-day-border-color: #5ca9cb;
$text-color: #363636;

#v2-date-range-picker {
  .DateRangePicker {
    display: block;
    .DateInput {
      input[type="text"] {
        border-color: transparent;
        padding: 0;
        height: auto;
        text-align: center;
      }
      input.DateInput_input__focused {
        border-bottom: 2px solid $carrier-navigation-blue;
      }
    }

    .DateRangePicker_picker {
      top: 41px !important; // need to override dynamic style set on html tag
    }

    .DateRangePickerInput__withBorder:focus-within {
      border: thin solid #66afe9;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
    }

    .DateRangePickerInput__withBorder {
      position: relative;
      margin: 0;
      background-color: #fff;
      width: 234px;
      border-radius: 0;
      color: #363636;
      font-weight: 400;
      border: 1px solid darkgrey;
      font-size: 0.875rem;
      line-height: 1.3125rem;
    }

    .DateInput {
      width: 80px;
    }

    .DateRangePickerInput_calendarIcon {
      padding: 10px 6px 2px;
      margin: 0;

      .date-picker-calendar-icon {
        background-image: url("/assets/images/date_picker_calendar.svg");
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }

    .DateRangePickerInput_clearDates_svg {
      height: 8px;
      width: 8px;
      fill: $carrier-mid-3-grey;
    }

    .CalendarDay {
      border: none;
      background: transparent;

      &:hover {
        border: 1px solid $hover-day-border-color;
      }
    }

    .CalendarDay__today {
      border: 1px solid $highlight-today-border-color;
    }

    .CalendarDay__selected_span {
      background: $date-range-fill-color;
      color: $text-color;
    }

    .CalendarDay__selected {
      background: $selected-day-fill-color;
      color: $white;
    }

    .DayPickerNavigation_button {
      position: absolute;
      top: 24px;

      &:first-child {
        left: 22px;
      }

      &:last-child {
        right: 22px;
      }
    }

    .DateRangePickerInput_clearDates {
      right: -4px;
    }

    .DateRangePickerInput_clearDates_default {
      background: transparent;
    }

    .CalendarMonth_caption {
      font-size: 14px;
      font-weight: normal;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
      border-right-color: $carrier-mid-3-grey;
    }

    .DateInput_fangShape {
      fill: transparent;
    }

    .DateInput_fangStroke {
      stroke: transparent;
    }

    .DayPickerKeyboardShortcuts_panel {
      margin: 0;
      padding: 8px;

      // expanding size to remove scroll
      bottom: -6px;
      left: -6px;
      right: -6px;
      top: -6px;

      .DayPickerKeyboardShortcuts_list {
        font-size: 12px;
      }

      .KeyboardShortcutRow_key {
        background-color: $carrier-mid-1-grey;
        padding: 0 4px;
      }
    }
  }
}
