@import "variables";

#find-loads-results-filters-button {
  padding: 6px;
  height: 2rem;
  margin-right: 4px;
  min-width: 6rem;

  @include respond-above(xs) {
    margin-right: 0px;
    min-height: unset;
  }

  .filters-button-text{
    padding-left: $space-sm;
    padding-right: $space-sm;
  }
}
