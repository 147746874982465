@import 'variables';

#find-loads-ver-2-page {
  .suggested-loads-title {
    padding: 8px 0;

    @include respond-below(md) {
      padding: 0;
    }
  }

  .reloads-title {
    padding: 0 $space-md;

    @include respond-below(md) {
      padding: 0;
    }
  }
}
