@import 'variables';
@import 'media-queries';

.radius-selection {
  display: none;

  &.open {
    display: block;
  }
}

.radius-input-icon {
  background-image: url("/assets/images/radius_icon.svg");
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.selection-header-radius {
  list-style-type: none;
  white-space: nowrap;

  .selection-menu-trigger {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: $normal;
    color: $carrier-dark-grey;
    padding: 7px 10px 7px 10px;
    border: transparent solid 1px; //will be filled in with color when active
    text-decoration: none;
    width: max-content;

    &:hover {
      background: $carrier-light-grey-1;
      border-radius: 2px;
    }

    &:active, &.active, &:focus {
      background: $carrier-active-light-blue;
      border-radius: 2px;
    }

    .glyphicon {
      padding-left: $space-sm;
      padding-top: $space-xxs;
      font-size: 0.5rem;
    }
  }

  .radius-selection.open {
    position: absolute;
    padding: 15px;
    background: $white;
    z-index: 1;
    border: 1px solid $carrier-light-2-grey;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); //bootstrap's dropdown value for box-shadow

    .radius-label {
      padding-bottom: $space-md;
    }

    .radius-controls {
      display: flex;
    }

    .radius-slider {
      margin-top: 20px;
      height: 3px;
      background: $carrier-navigation-blue;
      border-radius: 100px;
    }

    .radius-slider::-webkit-slider-thumb {
      width: 15px;
      -webkit-appearance: none;
      height: 15px;
      background-color: $carrier-navigation-blue;
      border-radius: 100px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }

    .radius-slider::-moz-range-thumb {
      width: 15px;
      -webkit-appearance: none;
      height: 15px;
      background-color: $carrier-navigation-blue;
      border-radius: 100px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }

    .radius-input-number {
      width: 58px;
      margin-left: $space-md;
    }
  }
}
